// 1.引入创建路由需要的组件
import { createRouter, createWebHistory} from 'vue-router'

// 2.配置系统所有路由页面
const routes = [
    {path:'',component:()=>import('../components/SelectClassPage.vue')},
    {path:'/login',component:()=>import('../components/LoginPage.vue')},
    {path:'/face',component:()=>import('../components/FacePage.vue')},
    {path:'/select_class',component:()=>import('../components/SelectClassPage.vue')},
    {path:'/select_grade',component:()=>import('../components/SelectGradePage.vue')},
    // {path:'/about',component:()=>import('../components/AboutPage.vue')},
    // {path:'/chengyuan',component:()=>import('../components/ChengyuanPage.vue')},
    // {path:'/zhuanjia',component:()=>import('../components/ZhuanjiaPage.vue')},
    // {path:'/chengguo',component:()=>import('../components/ChengguoPage.vue')},
    // {path:'/news',component:()=>import('../components/NewsPage.vue')},
    // {path:'/gongxu',component:()=>import('../components/GongxuPage.vue')},
    // {path:'/fuwu',component:()=>import('../components/FuwuPage.vue')},
    // {path:'/shebei',component:()=>import('../components/ShebeiPage.vue')},
    // {path:'/ziliao',component:()=>import('../components/ZiliaoPage.vue')},
    // // {path:'/shebei_detail',component:()=>import('../components/ShebeiView.vue')},
    // {path:'/zhuanjia_detail',component:()=>import('../components/ZhuanjiaView.vue')},
    // {path:'/chengguo_detail',component:()=>import('../components/ChengguoView.vue')},
    // {path:'/gongxu_detail',component:()=>import('../components/GongxuView.vue')},
    // {path:'/news_detail',component:()=>import('../components/NewsView.vue')},
    // {path:'/shebei_detail',component:()=>import('../components/ShebeiView.vue')},
    // {path:'/ziliao_detail',component:()=>import('../components/ZiliaoView.vue')},
    // {path:'/chengyuan_detail',component:()=>import('../components/ChengyuanView.vue')},
    // {path:'/user',component:()=>import('../components/UserPage.vue')},
    // {path:'/search',component:()=>import('../components/SearchPage.vue')},
]

// 3.创建路由实例
const router = createRouter({

    history: createWebHistory(), //使用history模式，hash模式使用 createWebHashHistory() 方法
    routes
  })

// 4.声明,为路由提供外部引用的入口
export default router
