import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { 
    Field, 
    CellGroup,
    Button,
    NavBar,
    Dialog,
    Toast,
    Image as VanImage,
    NoticeBar
 } from 'vant';

const app = createApp(App);
app.use(router);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(NavBar);
app.use(Dialog);
app.use(Toast);
app.use(VanImage);
app.use(NoticeBar);
app.use(VueCookies);
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.mount('#app');
